import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { UserContext } from "../../context/UserContext";
import { useGlobalFunction } from "../../generalFunction/generalFunction";
import api from "../../api";
import config from "../../config";
import { useNavigate } from "react-router-dom";


export function usePsb(props){
    const { isLoading, setIsLoading, alertValue, setAlertValue } = useGlobalFunction();
    const myState = useContext(UserContext);
    const navigate = useNavigate();
    const [alertState, setAlertState] = myState.alertState
    const mapRef = useRef(null);
    const [bottomState, setBottomState] = myState.bottomState;
    const [headerState, setHeaderState] = myState.headerState;
    const [modalState, modalDispatch] = myState.modalState;
    const [formData, setFormData] = useState({
        konsumen_display : {
            name : 'konsumen_display',
            value : '',
            label : 'Nama',
            required : true,
            show: true,
            showError : false,
            errorMsg : '',
            type: 'input_button',
            disabled : true,
        },
        konsumen : {
            name : 'konsumen',
            value : '',
            label : 'Nama',
            required : true,
            show: false,
            showError : false,
            errorMsg : '',
            type: 'text',
        },
        alamat : {
            name : 'alamat',
            value : '',
            label : 'Alamat',
            required : true,
            show: true,
            showError : false,
            errorMsg : '',
            type: 'textarea',
        },
        kabupaten : {
            name : 'kabupaten',
            value : '',
            label : 'Kabupaten',
            required : true,
            show: true,
            showError : false,
            errorMsg : '',
            type: 'select',
            obj : [],
        },
        kecamatan : {
            name : 'kecamatan',
            value : '',
            label : 'Kecamatan',
            required : true,
            show: true,
            showError : false,
            errorMsg : '',
            type: 'select',
            obj : [{value : '', label : '-- Pilih Kecamatan --'}],
        },
        desa : {
            name : 'desa',
            value : '',
            label : 'Desa',
            required : true,
            show: true,
            showError : false,
            errorMsg : '',
            type: 'select',
            obj : [{value : '', label : '-- Pilih Desa / Kelurahan --'}],
        },
        keterangan : {
            name : 'keterangan',
            value : '',
            label : 'Keterangan',
            required : true,
            show: true,
            showError : false,
            errorMsg : '',
            type: 'text',     
            inputType : 'text'       
        },
        produk_display : {
            name : 'produk_display',
            value : '',
            label : 'Produk',
            required : true,
            show: true,
            showError : false,
            errorMsg : '',
            type: 'input_button',   
            disabled : true         
        },        
        produk : {
            name : 'produk',
            value : '',
            label : 'Produk',
            required : true,
            show: true,
            showError : false,
            errorMsg : '',
            type: 'text',  
            disabled : true          
        },   
        foto_ktp : {
            value : '', 
            required : true, 
            showError : false, 
            errorMsg : '', 
            label : 'foto', 
            show: true, 
            inputType:  'foto',
            name: 'foto_ktp',
            grid : 6  
        },     
        lat : {
            value : '', 
            required : true, 
            showError : false, 
            errorMsg : '', 
            label : 'lat', 
            show: true,            
            type: 'text',
            name : 'lat',
            grid : 6,
        },        
        lng : {
            value : '', 
            required : true, 
            showError : false, 
            errorMsg : '', 
            label : 'lng', 
            show: true,
            type: 'text',
            name : 'lng',
            grid : 6,
        },
    })
    const inputFileRef = useRef(null); 
    const sheetRef = useRef()

    const fetchKabupaten = useCallback(async() => {
        setIsLoading(true);
        try{
            let params = {
                prov : 18,
                with_prov: true
            }
            const res = await api.get(`${config.endPoint.kabupaten}`, {params : params}).then(res => res.data);
            let tmp = [{value : '', label : '-- Pilih Kabupaten / Kota --'}]
            if (res) {
                res.results.map((post) => {
                    tmp.push({
                        value : post.kode,
                        label : post.nama
                    })
                    return true;
                })
            }
            setFormData(formData => ({...formData,
                kabupaten : {...formData.kabupaten, obj : tmp}
            }))
            setIsLoading(false);
        }catch(error){
            setIsLoading(false);            
            let msg = 'Kami sedang mengalami gangguan';
            let { response } = error;            
            if (response && response.data && response.data.message){
                msg = response.data.message
            }         
            setAlertValue(alertValue => ({...alertValue, show: true, text : 'Unable to fetch kabupaten / kota ', subText : msg, color : 'danger'}))
        }
    },[setIsLoading, setAlertValue])

    const fetchKecamatan = async(kabupaten=0) => {
        setIsLoading(true);
        try{
            let params = {
                kab : kabupaten,
                with_kab: true
            }
            const res = await api.get(`${config.endPoint.kecamatan}`, {params : params}).then(res => res.data);
            let tmp = [{value : '', label : '-- Pilih Kecamatan --'}]
            if (res) {
                res.results.map((post) => {
                    tmp.push({
                        value : post.kode,
                        label : post.nama
                    })
                    return true;
                })
            }
            setFormData(formData => ({...formData,
                kecamatan : {...formData.kecamatan, obj : tmp}
            }))
            setIsLoading(false);
        }catch(error){
            setIsLoading(false);            
            let msg = 'Kami sedang mengalami gangguan';
            let { response } = error;            
            if (response && response.data && response.data.message){
                msg = response.data.message
            }         
            setAlertValue(alertValue => ({...alertValue, show: true, text : 'Unable to fetch Kecamatan', subText : msg, color : 'danger'}))
        }
    }
    
    const fetchDesa = async(kecamatan=0) => {
        setIsLoading(true);
        try{
            let params = {
                kec : kecamatan,
                with_kec: true
            }
            const res = await api.get(`${config.endPoint.desa}`, {params : params}).then(res => res.data);
            let tmp = [{value : '', label : '-- Pilih Desa / Kelurahan --'}]
            if (res) {
                res.results.map((post) => {
                    tmp.push({
                        value : post.kode,
                        label : post.nama
                    })
                    return true;
                })
            }
            setFormData(formData => ({...formData,
                desa : {...formData.desa, obj : tmp}
            }))
            setIsLoading(false);
        }catch(error){
            setIsLoading(false);            
            let msg = 'Kami sedang mengalami gangguan';
            let { response } = error;            
            if (response && response.data && response.data.message){
                msg = response.data.message
            }         
            setAlertValue(alertValue => ({...alertValue, show: true, text : 'Unable to fetch Desa / Kelurahan', subText : msg, color : 'danger'}))
        }
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === 'kabupaten'){
            fetchKecamatan(value);
        }
        if (name === 'kecamatan'){
            fetchDesa(value);
        }
    }

    useEffect(() => {
        fetchKabupaten();
    },[fetchKabupaten])

    useEffect(() => {
        setHeaderState(headerState => ({...headerState, title : 'Tambah Pelanggan'})) ;
        setBottomState(bottomState => ({...bottomState, show : false}))
        // eslint-disable-next-line 
    },[]);

    const handleShowKontak = () => {
        modalDispatch({
            type: 'KONTAK',
            onClick : handleClickKontak,
            onClose : closeModalHandler
        });        
    }

    const handleClickKontak = (post) => {
        setFormData(formData => ({...formData,
            konsumen : {...formData.konsumen, value : post.id},
            konsumen_display : {...formData.konsumen_display, value : post.nama},            
        }))
        closeModalHandler();
    }

    const closeModalHandler = () => {
        modalDispatch({type: 'CLOSE'})
    }

    const handleShowProduk = () => {
        modalDispatch({
            type: 'PRODUK',
            onClick : handleClickProduk,
            onClose : closeModalHandler
        });  
    }

    const handleClickProduk = (post) => {
        setFormData(formData => ({...formData,
            produk : {...formData.produk, value : post.id},
            produk_display : {...formData.produk_display, value : post.nama},            
        }))
        closeModalHandler();
    }

    const handleChangeFoto = (e) => {        
        var nfile = e.target.files[0];
        var reader = new FileReader();
        reader.readAsDataURL(nfile);
        reader.onloadend = function() {			
			setFormData(formData => ({...formData, 
				// foto_url : {...formData.foto_url, value: nfile},
				foto_ktp : {...formData.foto_ktp, value: reader.result},
			}))
        }
	}

    const handleClickUploadButton = () => {
        inputFileRef.current.click()
    }

    // user location 
    useEffect(() => {
        navigator.geolocation.getCurrentPosition(function(position) {                        
            mapRef.current && mapRef.current.setCenter({ lat: position.coords.latitude, lng: position.coords.longitude });
            setFormData(formData => ({...formData,
                lat : {...formData.lat, value : position.coords.latitude},
                lng : {...formData.lng, value : position.coords.longitude},
            }))
        });
    },[])

    // const handleClickMaps = (obj) => {        
    const handleClickMaps = ({x, y, lat, lng, event}) => {        
        setFormData(formData => ({...formData,
            lat : {...formData.lat, value : lat},
            lng : {...formData.lng, value : lng},
        }))
    }

    const handleSubmit = async() => {
        setIsLoading(true);
        try{
            let obj = {...formData};
            let tmpForm = {};
            let countError = 0;

            Object.entries(obj).map(([index, post]) => {
                if (post.required && post.value === ''){
                    countError++;
                    post['showError'] = true;
                    console.log('ada error >>>', index, post)
                }else{
                    tmpForm[index] = post.value;
                }
                return true;
            })

            if (countError > 0){
                setIsLoading(false);
                
                setAlertState(alertState => ({...alertState, show : true, text : 'Lengkapi Data', subText : 'Periksa kembali data anda'}))
                setTimeout(() => {
                    handleCloseAlert();
                }, config.timeOutValue)
                return;
            }

            let newForm = new FormData();
            newForm.append('formData', JSON.stringify(tmpForm));

            let res = await api.post(`${config.endPoint.billingv1}`, newForm).then(res => res.data);
            setIsLoading(false);
            if (res){
                navigate('/')
            }
        }catch(error){
            setIsLoading(false);
            let { status } = error.response;

            if (status && status === 401){
                return window.location.href = "/pages/login/login3"
            }else{
                // setAlertValue(alertValue => ({...alertValue, show : true, text : 'Unable to fetch Data', color : 'error'}));
                setAlertState(alertState => ({...alertState, show : true, text : 'Unable to save data', subText : 'kami sedang mengalami gangguan'}))
                setTimeout(() => {
                    // setAlertValue(alertValue => ({...alertValue, show : false}));
                    handleCloseAlert();
                }, config.timeOutValue)
            }
        }
    }

    const handleCloseAlert = () => {
        setAlertState(alertState => ({...alertState, show : false}))
    }
    return {
        headerState, bottomState, setBottomState,
        formData, setFormData, setHeaderState,
        handleShowKontak, modalState, modalDispatch, sheetRef, isLoading,
        alertValue, handleChange, handleShowProduk, handleChangeFoto, inputFileRef,
        handleClickUploadButton,  handleClickMaps, mapRef,
        handleSubmit, alertState
    }
}