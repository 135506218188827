// import { BottomSheet } from "react-spring-bottom-sheet";
// import BottomSheetComp from "../../component/BottomSheetComp";
import FormInput from "../../component/FormInput";
// import MapsComponent from "./Maps/MapsComponent";
import { usePsb } from "./psbFunction";
import 'react-spring-bottom-sheet/dist/style.css'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import GoogleMapReact from 'google-map-react';
import config from "../../config";



const PsbPage = (props) => {
    const { formData, setFormData, handleShowKontak, handleChange,
        handleShowProduk, handleChangeFoto, handleClickUploadButton, inputFileRef,
         handleClickMaps, mapRef, handleSubmit
    } = usePsb();
    return(
        <div className={`px-2 ${config.bgColor} pt-5 pb-14 dark:bg-base-400 dark:text-base-100`}>
            <div className='px-5 bg-white w-full h-full pt-8 rounded-lg dark:bg-base-400 dark:text-base-100'>
                
                <FormInput
                    {...formData.konsumen_display}
                    formData={formData}
                    setFormData={setFormData}
                    onClick={handleShowKontak}
                />
                <div className='mt-5'>
                    <FormInput
                        {...formData.alamat}
                        formData={formData}
                        setFormData={setFormData}
                    />
                </div>
                
                <div className='mt-5'>
                    <FormInput
                        {...formData.kabupaten}
                        formData={formData}
                        setFormData={setFormData}
                        onChange={handleChange}
                    />
                </div>
                <div className='mt-5'>
                    <FormInput
                        {...formData.kecamatan}
                        formData={formData}
                        setFormData={setFormData}
                        onChange={handleChange}
                    />
                </div>
                <div className='mt-5'>
                    <FormInput
                        {...formData.desa}
                        formData={formData}
                        setFormData={setFormData}
                        onChange={handleChange}
                    />
                </div>
                <div className='mt-5'>
                    <FormInput
                        {...formData.keterangan}
                        formData={formData}
                        setFormData={setFormData}
                        onChange={handleChange}
                    />
                </div>
                <div className='mt-5'>
                    <FormInput
                        {...formData.produk_display}
                        formData={formData}
                        setFormData={setFormData}
                        onChange={handleChange}
                        onClick={handleShowProduk}
                    />
                </div>
                <div className='mt-5'>
                    <input
                        style={{ display: 'none' }}
                        id="upload-photo"
                        name="upload-photo"
                        type="file"
                        onChange={handleChangeFoto}
                        ref={inputFileRef}
                    />
                    <button className='bg-[#c10000] px-5 py-2 text-[13px] rounded-md text-white dark:bg-base-600 dark:text-base-100' onClick={handleClickUploadButton}>
                        Upload Foto KTP
                    </button>
                    { formData.foto_ktp.value &&
                        <img src={formData.foto_ktp.value} className='w-full object-cover mt-3' alt="ktp"/>
                    }
                </div>
                <div className='mt-5 mb-5x'>Masukan Lokasi Pelanggan</div>
                <div style={{ height: '100vh', width: '100%', marginBottom: 20 }}>
                    <GoogleMapReact
                        bootstrapURLKeys={{ key: config.googleMapKey }}
                        defaultCenter={[config.initLocation.lat, config.initLocation.lng]}
                        defaultZoom={20}                    
                        // center={[userLocation.lat, userLocation.lng]}
                        yesIWantToUseGoogleMapApiInternals
                            onGoogleApiLoaded={({ map }) => {
                            mapRef.current = map;
                        }}
                        onClick={handleClickMaps}
                        // options={{
                        //     styles: [{ stylers: [{ 'saturation': 10 }, { 'gamma': 0.1 }] }]
                        // }}                        
                    >                                        
                        { formData.lat.value && formData.lng.value &&
                            <div className='text-red-600 text-[42px]'
                                lat={formData.lat.value}
                                lng={formData.lng.value}
                            >
                                <FontAwesomeIcon icon={faLocationDot} />
                            </div>                 
                        }
                    </GoogleMapReact>
                </div>
                <div>
                    <button 
                        onClick={handleSubmit}
                        className='bg-blue-600 text-white text-[13px] w-full h-full text-center py-2 mb-8 rounded-full dark:bg-base-600 dark:text-base-100'>
                        Simpan
                    </button>
                </div>
            </div>
        </div>
    )
}
export default PsbPage;