// eslint-disable-next-line 
export default {    
    fetchStatus: {
      READY: "READY",
      FETCHING: "FETCHING"
    },
    apiBaseUrl: {
        development: "http://127.0.0.1:8000",        
        // development: "https://workspace.gifihome.id",        
        production: "https://workspace.gifihome.id",        
    },
    webPage :{
      development : "http://localhost:3000",
      production : "http://localhost:3001"
    },
    wsServer : {
      development : "ws://127.0.0.1:8000/ws/",
      production : '',
    },
    localStorageKey : {      
      USER: "__gifiSalesForce",
      ACCESS_TOKEN: "__gifiSalesToken",      
      PERUSAHAAN : '_gifiSalesperusahaanInfo',
    },
    initLocation : {
      lat : -5.366180643635241, 
      lng : 105.24861754093926
    },
    cookiesName : 'gifi_sales_cookie',    
    cookiesNameToken : '__gifiSales_token',    
    expiresSession : 86400,
    itemPerPage : 15,
    bgColor : 'bg-[#F6F6F6]',
    timeOutValue : 10000,    
    googleMapKey : 'AIzaSyDm751ucwcFMFw6dztSqEfgCKeYoz2hTYc',       
    endPoint : {
      // auth
      permissionGroup : '/auth/api/v1/group-permissions/',
      getExpiredLink : `/auth/api/v1/get-expired-link/`,
      resetPassword : `/auth/api/v1/reset-password/`,
      createPassword : `/auth/api/create-password/`,
      login : `/auth/api/v1/sales/login/`,
      forgotPassword : `/auth/api/v1/sales/forgot-password/`,
    
      
      // dashboard
      billingResume : 'api/v1/dashboard/resume/',
      omzet : `/dashboard/api/v1/sales/omzet/`,
      piutang : `/dashboard/api/v1/sales/piutang/`,

      // billing
      billingState : `/api/v1/billing-state/`,
      billing : `/billing/api/v1/sales/billing/`,      
      kontak : `/kontak/api/v1/sales/kontak/`,
      billingv1 : `/api/v1/billing/`,

      // master
      provinsi : `/master/api/v1/provinsi/`,
      kabupaten : `/master/api/v1/kabupaten/`,
      kecamatan : `/master/api/v1/kecamatan/`,
      desa : `/master/api/v1/desa/`,

      // produk
      produk : `/produk/api/v1/produk/`,
    }
}